import * as React from "react"
import { graphql } from "gatsby"
const Layout = React.lazy(() => import("../components/layout"))
const Banner = React.lazy(() => import("../components/sections/Intro2"))
const BannerFijo = React.lazy(() => import("../components/sections/BannerFijo"))
const ProductoRecomendado = React.lazy(() => import("../components/sections/ProductosRecomendados"))
const Anuncios = React.lazy(() => import("../components/sections/Anuncios"))
const ImagenesSegmentos = React.lazy(() => import("../components/sections/ImagenesSegmentos"))
const Marcas = React.lazy(() => import("../components/sections/Marcas"))
const ProductosVistos = React.lazy(() => import("../components/ProductosVistos/vistosRecientemente"))
const SeoEmpresa = React.lazy(() => import("../components/SEO/seoEmpresa"))
const Suscribete = React.lazy(() => import("../components/sections/Suscribete"))

const IndexPage = ({ data }) => {
  let {
    allBannerTipo4,
    allBannerAnuncioPF,
    allBannerAnuncios,
    SegmentosImagenes,
    allBannerMarcas,
    ProductosRecomendados,
  } = data
  const banner = allBannerTipo4.edges.map(e => e.node)
  const bannerAnuncionPF = allBannerAnuncioPF.edges.map(e => e.node)
  const bannerAnuncios = allBannerAnuncios.edges.map(e => e.node)
  const BannerMarcas = allBannerMarcas.edges.map(e => e.node)
  const PProductosRecomendados = ProductosRecomendados.edges.map(e => e.node)
  const SegmentosImagen = SegmentosImagenes.edges.map(e => e.node)
  const isSSR = typeof window === "undefined"

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Layout>
            <div className="landing">
              <SeoEmpresa
                title="L& Grepsa, S.A."
                keywords={[
                  "Suministros",
                  "Limpieza",
                  "distribuidora",
                  "producto de limpieza",
                  "venta de producto de limpieza",
                  "productos de limpieza",
                  "productos de limpieza industrial",
                  "venta de tapetes ",
                  "venta de cloro por mayor ",
                  "venta de papel de baño ",
                  "venta de trapeadores",
                ]}
                image={
                  "https://disdelsa.com/imagenes/BannerImagen-img2059-19-8-2020-84427.png"
                }
                Marcas={BannerMarcas}
              />
              <Banner banner={banner} />
              <Anuncios bannerAnuncios={bannerAnuncios} />
              <ImagenesSegmentos SegmentosImagen={SegmentosImagen} />
              <ProductoRecomendado Recomendados={PProductosRecomendados} />
              <BannerFijo banner={bannerAnuncionPF} />
              <ProductosVistos />
              <Marcas BannerMarcas={BannerMarcas} />
              <Suscribete />
            </div>
          </Layout>
        </React.Suspense>
      )}
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allBannerTipo4: allBannersJsonJson(filter: { IdTipoBanner: { in: 20} }) {
      edges {
        node {
          Imagen
          ImagenBanner
          BannerImagenMovil
        }
      }
    }
      allBannerAnuncioPF: allBannersJsonJson(filter: { IdTipoBanner: { in: 23} }) {
      edges {
        node {
          Imagen
          ImagenBanner
          BannerImagenMovil
        }
      }
    }
    allBannerAnuncios: allBannersJsonJson(filter: { IdTipoBanner: { eq: 21 } }) {
      edges {
        node {
          Imagen
          ImagenBanner
          BannerImagenMovil
          Subtitulo
        }
      }
    }
    allBannerMarcas: allBannersJsonJson(filter: { IdTipoBanner: { eq: 24 } }) {
      edges {
        node {
          Imagen
          ImagenBanner
          BannerImagenMovil
          Titulo
          Subtitulo
        }
      }
    }
    allBannerNewCategorias: allBannersJsonJson(
      filter: { IdTipoBanner: { eq: 15 } }
    ) {
      edges {
        node {
          Imagen
          ImagenBanner
          BannerImagenMovil
          Titulo
          Subtitulo
        }
      }
    }
    landingPage: allDataJson {
      edges {
        node {
          Paginas {
            LandingPages {
              EntityID
              IdDivision
              Division
              IdLandingPage
              IdCompania
              TipoLanding
              Titulo
              Subtitulo
              CuerpoPagina
              CuerpoPajinaMovil
            }
          }
        }
      }
    }
    SegmentosImagenes: allMenuJsonJson {
      edges {
        node {
          NombreSegmento
          Imagen
          SegmentoAux
        }
      }
    }
    ProductosRecomendados: allProductosRecomendadosJson(
      filter: { Tipo: { in: 1 } }
    ) {
      edges {
        node {
          id
          IdProducto
          IdRelacion
          Descripcion
          Imagen
          BaseUnidad
          ItemCodeAux
          BaseDefault
          BaseFardo
          Fardo
          Categoria
          IdCategoria
          Unidad
        }
      }
    }
    ProductosPromociones: allProductosRecomendadosJson(
      filter: { Tipo: { in: 5 } }
    ) {
      edges {
        node {
          id
          IdProducto
          IdRelacion
          Descripcion
          Imagen
          BaseUnidad
          ItemCodeAux
          BaseDefault
          BaseFardo
          Fardo
          Categoria
          IdCategoria
          Unidad
        }
      }
    }
    ProductosMasVendidos: allProductosRecomendadosJson(
      filter: { Tipo: { in: 4 } }
    ) {
      edges {
        node {
          id
          IdProducto
          IdRelacion
          Descripcion
          Imagen
          BaseUnidad
          ItemCodeAux
          BaseDefault
          BaseFardo
          Fardo
          Categoria
          IdCategoria
          Unidad
        }
      }
    }
  }
`
